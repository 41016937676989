<template>
  <div class="Map">
    <div class="title">大卫美术教育校区全国分布图</div>
    <div class="colorTips"></div>
    <clock/>
    <div id="map">
    </div>
  </div>
</template>

<script>
import Clock from "../components/Clock";
import service from "../utils/axios";
import {Scene, LineLayer, PointLayer} from '@antv/l7';
import {Mapbox} from '@antv/l7-maps';
import {CountryLayer} from '@antv/l7-district';
import {changePage} from "../utils/getPageTime";

export default {
  name: "Map",
  components: {Clock},
  data() {
    return {
      scene: null,
      scene2: null,
      country: null,
      timeout: null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchData()
      this.timeout = await changePage(this.$router,'主屏')

    },
    async fetchData() {
      const flyline = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/campus/toCoordinate'); // 流向坐标
      const dot = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/campus/coordinates'); //校区坐标
      const colorList = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/province/colour');
      let _flyLine = flyline && flyline.map(item => {
        return {coord: [[parseFloat(item.coordinates.longitude), parseFloat(item.coordinates.latitude)], [parseFloat(item.campusCoordinates.longitude), parseFloat(item.campusCoordinates.latitude)]]}
      })
      this.setMap(_flyLine, dot, colorList)
    },
    setMap(flyline, dot, colorList) {
      let _zoom = 3.8;
      let _attachWidth = 103;
      let _attachHeight = 125;
      let _attachBottom = 119;
      let _attachLeft = 203;
      if (window.screen.width === 1280){
        _zoom = 3.2
        _attachWidth = 69
        _attachHeight = 83
        _attachBottom = 79
        _attachLeft = 135
      }
      let scene = new Scene({
        id: 'map',
        logoVisible: false,
        preserveDrawingBuffer: true,
        animate: true,
        map: new Mapbox({
          center: [106.368652, 37.93866],
          pitch: 0,
          style: 'blank',
          zoom: _zoom,
          minZoom: 0,
          maxZoom: 10,
        }),
      });
      this.scene = scene;
      const attachMapContainer = document.createElement('div');
      attachMapContainer.id = 'attach';
      attachMapContainer.style.cssText = `position: absolute;
height: ${_attachHeight}px;
width: ${_attachWidth}px;
left: ${_attachLeft}px;
bottom: ${_attachBottom}px;
border-radius: 3px;
border: 1px solid #006AEA;`;
      document.getElementById('map').parentElement.append(attachMapContainer);

      scene.on('loaded', () => {
        let country = new CountryLayer(scene, {
          data: [],
          joinBy: ['NAME_CHN', 'name'],
          depth: 1,
          provinceStroke: '#3ECBF9',
          cityStroke: '#006AEA',
          cityStrokeWidth: 1,
          chinaNationalWidth: 3,
          chinaNationalStroke: '#3FCAFA',
          autoFit: false,
          label: {
            enable: true,
            strokeOpacity: 0,
            strokeWidth: 0,
            color: '#FFF',
            size: window.screen.width === 1280? 8: 16,
          },
          fill: {
            color: {
              field: 'NAME_CHN',
              values: name => {
                let _name = name.replace(/省|自治区|特别行政区|市|壮族|回族|维吾尔/g, '')
                if (colorList.campusList_0_15.indexOf(_name) !== -1) {
                  return '#2889FC'
                } else if (colorList.campusList_16_30.indexOf(_name) !== -1) {
                  return '#046BE5'
                } else if (colorList.campusList_31_45.indexOf(_name) !== -1) {
                  return '#0243C4'
                } else if (colorList.campusList_46_60.indexOf(_name) !== -1) {
                  return '#093A9C'
                } else if (colorList.campusList_76_90.indexOf(name) !== -1) {
                  return '#052C7B'
                } else {
                  return '#071C71'
                }
              },
            },
          },
          popup: {
            enable: false,
            Html: props => {
              return `<span>${props.NAME_CHN}</span>`;
            }
          }
        });
        this.country = country;
        country.on('loaded', ()=>{
          let pol = new PointLayer({zIndex: 3})
              .source(dot, {
                parser: {
                  type: 'json',
                  x: 'longitude',
                  y: 'latitude',
                },
              })
              .shape('circle')
              .color('#FF9900')
              .animate(true)
              .size(25)
              .style({
                opacity: 1.0,
              });

          let lil = new LineLayer({zIndex: 5, blend: 'default'})
              .source(flyline, {
                parser: {
                  type: 'json',
                  coordinates: 'coord'
                },
              })
              .color('#ff6b34')
              .shape('arc3d')
              .size(2)
              .active(true)
              .animate({
                interval: 0.2,
                trailLength: 0.5,
                duration: 4
              })
              .style({
                opacity: 1, lineType: 'dash', dashArray: [5, 5]
              });
          scene.addLayer(pol)
          scene.addLayer(lil)
        })
      });

      // 添加附图，附图需要和主图保持一致

      const scene2 = new Scene({
        id: 'attach',
        logoVisible: false,
        map: new Mapbox({
          center: [113.60540108435657, 12.833692637803168],
          pitch: 0,
          style: 'blank',
          zoom: 1.93,
          minZoom: 0,
          maxZoom: 3,
          interactive: false
        })
      });
      this.scene2 = scene2;
      scene2.on('loaded', () => {
        new CountryLayer(scene2, {
          data: [],
          label: {
            enable: false
          },
          popup: {
            enable: false
          },
          autoFit: false,
          provinceStroke: '#3ECBF9',
          provinceStrokeWidth: 0.1,
          depth: 1,
          fill: {
            color: {
              field: 'NAME_CHN',
              values: [
                '#006AEA'
              ]
            }
          }
        });
      });
    },
  },
  beforeRouteLeave(){
    this.scene && this.scene.destroy();
    this.scene2 && this.scene2.destroy();
    this.country && this.country.destroy();
    this.timeout && clearTimeout(this.timeout);
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";

.Map {
  .title {
    @include pageTitleMix;
  }

  .colorTips {
    width: 67px;
    height: 232px;
    background-image: url("../assets/images/biaoqian.png");
    @include backgroundMix;
    position: absolute;
    right: 260px;
    bottom: 120px;
    @media (max-width: 1280px){
      width: 45px;
      height: 155px;
      right: 173px;
      bottom: 80px;
    }
  }
}
</style>
